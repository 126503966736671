import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalFooter,
  EuiButton,
  EuiIcon,
} from "@elastic/eui";

import { StyledSpacer } from "src/components/Global/StyledComponents";

import { PlanViewComponent } from "src/interfaces";
import { getAssetBalanceTotals } from "src/store/account/selector";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getEmergencyMonthlyExpenses,
  getFormattedGoalData,
} from "src/store/planBuild/selector";
import {
  currentPlanAllocationTotals,
  currentPlanIncomeTotal,
  currentPlanExpenseTotal,
  currentPlanRiskManagementTotal,
  getCurrentPlanSurplus,
  getIsMonthly,
  isCurrentPlanImplemented,
  livePlanAllocationTotals,
  livePlanExpenseTotal,
  livePlanIncomeTotal,
  livePlanRiskManagementTotal,
} from "src/store/planBuild/selector";
import { usePlanUtils } from "src/store/planBuild/util";
import CenterContainer from "../../Components/CenterContainer";
import GoalCard from "./GoalCard";
import GoalMessage from "./GoalMessage";

const Goals: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const plan: any = useSelector(getCurrentPlan);
  const assetBalanceTotals = useSelector(getAssetBalanceTotals);
  const monthlyExpenses = useSelector(getEmergencyMonthlyExpenses);
  const goals = useSelector(getFormattedGoalData);
  let runningCashTotal = assetBalanceTotals.cash_value || 0;
  const emergencyFundTarget = monthlyExpenses * plan.emergencymonths;
  const emergencyFundRequired = emergencyFundTarget > runningCashTotal;
  runningCashTotal -= emergencyFundTarget;
  const noCashAllocation = !plan.allocations[0].cash_value;
  const { syncGoals } = usePlanUtils();

  // Get necessary values from selectors
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const isMonthly = useSelector(getIsMonthly);

  const planIncomeTotal = useSelector(currentPlanIncomeTotal);
  const planExpenseTotal = useSelector(currentPlanExpenseTotal);
  const planRiskTotal = useSelector(currentPlanRiskManagementTotal);
  const planRemainingMoney = useSelector(getCurrentPlanSurplus);

  const liveIncomeTotal = useSelector(livePlanIncomeTotal);
  const liveExpenseTotal = useSelector(livePlanExpenseTotal);
  const liveRiskTotal = useSelector(livePlanRiskManagementTotal);

  const { assetsTotal: planAssetsTotal, debtsTotal: planDebtsTotal } = useSelector(currentPlanAllocationTotals);
  const { assetsTotal: liveAssetsTotal, debtsTotal: liveDebtsTotal } = useSelector(livePlanAllocationTotals);

  // Determine which totals to use based on implementation
  const { incomeTotal, expenseTotal, riskTotal, assetsTotal, debtsTotal } = useMemo(() => {
    if (!currentPlanIsImplemented) {
      return {
        incomeTotal: planIncomeTotal,
        expenseTotal: planExpenseTotal,
        riskTotal: planRiskTotal,
        assetsTotal: planAssetsTotal,
        debtsTotal: planDebtsTotal,
      };
    }

    return {
      incomeTotal: liveIncomeTotal,
      expenseTotal: liveExpenseTotal,
      riskTotal: liveRiskTotal,
      // Note: According to the original code, when implemented, it still uses planAssetsTotal and planDebtsTotal
      assetsTotal: planAssetsTotal,
      debtsTotal: planDebtsTotal,
    };
  }, [
    currentPlanIsImplemented,
    planIncomeTotal, planExpenseTotal, planRiskTotal, planAssetsTotal, planDebtsTotal,
    liveIncomeTotal, liveExpenseTotal, liveRiskTotal, liveAssetsTotal, liveDebtsTotal
  ]);

  // Compute remaining money
  const remainingMoney = !currentPlanIsImplemented
    ? planRemainingMoney
    : incomeTotal - expenseTotal - riskTotal - assetsTotal - debtsTotal;


  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const newGoals = syncGoals();
    dispatch(updateCurrentPlan({ goals: newGoals }));
  }, []);

  useEffect(() => {
    if (remainingMoney < 0) {
      setShowModal(true);
    }
  }, [remainingMoney]);

  useEffect(() => {
    console.log("RemainingMoney in Goals:", remainingMoney);
  }, [remainingMoney]);

  const onNext = () => {
    dispatch(savePlan(PLAN_BUILD_STEPS.RECOMMENDATIONS));
  };

  const reorderGoals = (sourceIndex: number, destinationIndex: number) => {
    const newGoals = plan.goals.slice(1);
    const sourceItem = newGoals.splice(sourceIndex, 1);
    newGoals.splice(destinationIndex, 0, sourceItem[0]);
    dispatch(updateCurrentPlan({ goals: [plan.goals[0], ...newGoals] }));
  };

  const onDragEnd = ({ source, destination }: any) =>
    reorderGoals(source.index, destination.index);

  const moveGoalUp = (index: number) => reorderGoals(index, index - 1);
  const moveGoalDown = (index: number) => reorderGoals(index, index + 1);

  let firstSavingGoalNotMet = noCashAllocation && !emergencyFundRequired;

  console.log({ goals, plan });

  return render({
    component: (
      <>
        <EuiFlexGroup className="ai-flex-content">
          <EuiFlexItem>
            <div className="ai-content-title">
              <h1>Goal Ranking</h1>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
        <StyledSpacer size="16px" />
        <EuiText className="ai-secondary-text">
          <p>
            The key to achieving your financial goals is to focus on one short-term goal at a time. Drag and drop your goals below to rank them accordingly.
          </p>
        </EuiText>
        <StyledSpacer size="32px" />
        {emergencyFundRequired && (
          <GoalCard
            goal={{
              title: "Save for emergency fund",
              id: 1,
              savingsGoal: emergencyFundTarget,
            }}
            index={0}
            message={
              <>
                <GoalMessage
                  level="info"
                  contentKey="emergencyFund"
                  goal={{}}
                />
                {noCashAllocation && (
                  <GoalMessage
                    level="warning"
                    contentKey="noCashAllocation"
                    goal={{}}
                  />
                )}
              </>
            }
          />
        )}
        <EuiDragDropContext onDragEnd={onDragEnd}>
          <EuiDroppable droppableId="goals-list" spacing="m">
            {goals.slice(1).map((goal: any, index: number) => {
              let message: any = undefined;
              switch (goal.goaltype) {
                case "down":
                  if (
                    firstSavingGoalNotMet &&
                    runningCashTotal < goal.savingsGoal
                  ) {
                    firstSavingGoalNotMet = false;
                    message = (
                      <GoalMessage
                        level="warning"
                        contentKey="noCashAllocation"
                        goal={{}}
                      />
                    );
                  } else {
                    runningCashTotal -= goal.savingsGoal || 0;
                    if (runningCashTotal > 0) {
                      message = (
                        <GoalMessage
                          level="success"
                          contentKey="savingsAchieved"
                          goal={{}}
                        />
                      );
                    }
                  }
                  break;
                case "payoff":
                  firstSavingGoalNotMet = false;
                  runningCashTotal -= goal.balance;
                  if (goal.lifeevent_id) {
                    message = (
                      <GoalMessage
                        level="info"
                        contentKey="futureDebt"
                        goal={goal}
                      />
                    );
                  }
                  break;
                case "fedloanpayoff":
                case "privloanpayoff":
                case "perkinsloanpayoff":
                  firstSavingGoalNotMet = false;
                  runningCashTotal -= goal.balance;
                  break;
                default:
                  firstSavingGoalNotMet = false;
                  break;
              }
              return (
                <EuiDraggable
                  spacing="m"
                  key={"" + goal.id}
                  index={index}
                  draggableId={"" + goal.id}
                  customDragHandle
                  hasInteractiveChildren
                  className="w-full"
                >
                  {(providedProps) => (
                    <GoalCard
                      goal={goal}
                      index={index + 1}
                      providedProps={providedProps}
                      moveUp={index > 0 ? () => moveGoalUp(index) : undefined}
                      moveDown={
                        index < plan.goals.length - 1
                          ? () => moveGoalDown(index)
                          : undefined
                      }
                      message={message}
                    />
                  )}
                </EuiDraggable>
              );
            })}
          </EuiDroppable>
        </EuiDragDropContext>
        {showModal && (
          <EuiModal onClose={() => setShowModal(false)}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Available Funds are Negative
              </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <p>
                Your available funds are negative, meaning that you have exceeded your budget. Please adjust your Day-to-Day Money or Money for Future Self so there are available funds left over.
              </p>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButton onClick={() => setShowModal(false)}>Ok</EuiButton>
            </EuiModalFooter>
          </EuiModal>
        )}
      </>
    ),
    onNext,
  });
};

export default Goals;
