import React, { useState, useEffect } from 'react';
import {
    EuiBasicTable,
    EuiFieldText,
    EuiButton,
    EuiFormRow,
    EuiButtonIcon,
    EuiBasicTableColumn,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui';
import { DollarTextField, PercentTextField } from "src/utils/inputMask";
import { StyledSpacer } from 'src/components/Global/StyledComponents';
import useWindowSize from 'src/hooks/useWindowSize';

interface Column {
    field: string;
    label: string;
    type?: string;
    formatter?: (value: any) => string;
}

interface AssetsDebtsTableProps {
    title: string;
    columns: Column[];
    data: Array<any>;
    onAdd: () => void;
    onDelete: (item: any, index: number) => void;
    onSave: (item: any, updates: any, index: number) => void;
    summaryRow?: Record<string, any>;
    addLabel?: string;
    isDebt?: boolean; // To toggle between assets and debts
}

const AssetsDebtsTable: React.FC<AssetsDebtsTableProps> = ({
    title,
    columns,
    data,
    onAdd,
    onDelete,
    onSave,
    summaryRow,
    addLabel = 'Add Item',
    isDebt = false,
}) => {
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editableData, setEditableData] = useState(data);
    const windowSize = useWindowSize();

    useEffect(() => {
        setEditableData(data);
    }, [data]);

    const handleEdit = (index: number) => {
        setEditingIndex(index);
    };

    const handleSave = (index: number) => {
        onSave(editableData[index], editableData[index], index);
        setEditingIndex(null);
    };

    const handleFieldChange = (index: number, field: string, value: any) => {
        setEditableData((prevData) =>
            prevData.map((item, idx) =>
                idx === index
                    ? {
                        ...item,
                        [field]: value,
                        ...(field === 'monthly' && { annual: value * 12 }),
                        ...(field === 'annual' && { monthly: value / 12 }),
                    }
                    : item
            )
        );
    };

    // Define columns with in-row editing logic
    const tableColumns: EuiBasicTableColumn<any>[] = columns
        .filter(col => windowSize.width > 767 || (col.field !== 'annual' && col.field !== 'percent')) // Conditionally exclude the 'annual' and 'percent' columns
        .map((col) => ({
            field: col.field,
            name: col.label,
            render: (value: any, record: any) => {
                const index = editableData.indexOf(record);
                const isEditing = index === editingIndex;

                // Handle "type" field
                if (col.field === 'type') {
                    return record.typeLabel || value; // Use typeLabel if available
                }

                if (isEditing) {
                    if (col.field === 'owner') {
                        return (
                            <EuiFieldText
                                value={editableData[index][col.field] || ''}
                                onChange={(e: any) =>
                                    handleFieldChange(index, col.field, e.target.value)
                                }
                            />
                        );
                    }
                    
                    if (col.type === 'number') {
                        return (
                            <DollarTextField
                                value={editableData[index][col.field] || ''}
                                onChange={(e: any) =>
                                    handleFieldChange(index, col.field, Number(e.target.value))
                                }
                                eui
                            />
                        );
                    }

                    return (
                        <EuiFormRow>
                            <PercentTextField
                                className="input"
                                placeholder="%"
                                value={editableData[index][col.field] || ''}
                                onChange={(e: any) =>
                                    handleFieldChange(index, col.field, e.target.value)
                                }
                                eui
                            />
                        </EuiFormRow>
                    );
                }

                return col.formatter ? col.formatter(value) : value;
            },
        }));

    // Define the "Actions" column
    tableColumns.push({
        name: 'Actions',
        actions: [
            {
                name: editingIndex === null ? 'Edit' : 'Save',
                description: editingIndex === null ? 'Edit this row' : 'Save changes',
                icon: (item) =>
                    editingIndex === editableData.indexOf(item) ? 'check' : 'pencil',
                type: 'icon',
                onClick: (item) => {
                    const rowIndex = editableData.indexOf(item);
                    editingIndex === rowIndex ? handleSave(rowIndex) : handleEdit(rowIndex);
                },
            },
            {
                name: 'Delete',
                description: 'Delete this row',
                icon: 'trash',
                type: 'icon',
                color: 'danger',
                onClick: (item) => onDelete(item, editableData.indexOf(item)),
            },
        ],
    } as EuiBasicTableColumn<any>);

    const totalRow = {
        ...summaryRow,
        type: 'Total',
    };

    return (
        <>
            <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                <EuiFlexItem>
                    <h2>{title}</h2>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={onAdd} iconType="plusInCircle" color="text" style={{ border: '1px solid' }}>
                        {addLabel}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            <StyledSpacer size="16px" />
            <EuiBasicTable
                responsive={false}
                items={[...editableData, totalRow]}
                columns={tableColumns}
                rowHeader="type"
                className="table-border"
            />
        </>
    );
};

export default AssetsDebtsTable;