import React, { useState, useEffect, useMemo } from 'react';
import {
    EuiBasicTable,
    EuiFieldText,
    EuiButtonIcon,
    EuiButton,
    EuiSuperSelect,
    EuiFlexGroup,
    EuiFlexItem,
    EuiBasicTableColumn,
} from '@elastic/eui';
import { MappedCashflow, RISK_MANAGEMENT_TYPES } from 'src/interfaces';
import { DollarTextField } from 'src/utils';
import { StyledSpacer } from 'src/components/Global/StyledComponents';
import { Global, css } from '@emotion/react';
import useWindowSize from 'src/hooks/useWindowSize';

const rmStyles = css`
    .euiSuperSelect {
        width: 100%;
    }
    .euiTableCellContent__text {
        font-size: 16px !important;
    }
    .euiTableCellContent {
        font-size: 16px !important;
    }
`;

interface Column {
    field: keyof MappedCashflow;
    label: string;
    type?: string;
    formatter?: (value: any) => string;
}

interface InsuranceTableProps {
    title: string;
    columns: Column[];
    data: MappedCashflow[];
    onSave: (item: MappedCashflow, update: Partial<MappedCashflow>, index: number) => void;
    onDelete: (item: MappedCashflow, index: number) => void;
    addLabel?: string;
    onAdd: () => void;
    totalIncome: number;
}

const RiskManagementTable: React.FC<InsuranceTableProps> = ({
    title,
    columns,
    data,
    onSave,
    onDelete,
    addLabel,
    onAdd,
    totalIncome,
}) => {
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editableData, setEditableData] = useState<MappedCashflow[]>([]);
    const windowSize = useWindowSize();

    useEffect(() => {
        setEditableData(data);
    }, [data]);

    const updateItem = (
        index: number,
        { annual, monthly, percent }: { annual?: number | null; monthly?: number | null; percent?: number | null }
    ) => {
        setEditableData(prevData =>
            prevData.map((item, idx) => {
                if (idx !== index) return item;

                const updated: MappedCashflow = { ...item };
                if (annual !== undefined) updated.annual = annual;
                if (monthly !== undefined) updated.monthly = monthly;
                if (percent !== undefined) updated.percent = percent;

                return updated;
            })
        );
    };

    const handlePercentChange = (index: number, rawValue: string) => {
        const rawStr = String(rawValue); // Ensure string
        const numericStr = rawStr.replace(/[^0-9.]/g, '');
        const newPercent = numericStr.trim() === '' ? null : parseFloat(numericStr);
        
        const newAnnual = newPercent !== null && totalIncome ? (newPercent / 100) * totalIncome : 0;
        const newMonthly = newAnnual ? Math.round(newAnnual / 12) : 0;

        updateItem(index, {
            percent: newPercent,
            annual: newPercent !== null ? newAnnual : null,
            monthly: newPercent !== null ? newMonthly : null,
        });
    };

    const handleAnnualChange = (index: number, rawValue: string | number) => {
        const rawStr = String(rawValue); // Ensure string
        const annualValue = parseFloat(rawStr.replace(/[^0-9.]/g, ''));
        if (isNaN(annualValue)) {
            updateItem(index, { annual: null, monthly: null, percent: null });
            return;
        }

        const newAnnual = annualValue;
        const newMonthly = Math.round(newAnnual / 12);
        const newPercent = totalIncome ? (newAnnual / totalIncome) * 100 : 0;

        updateItem(index, {
            annual: newAnnual,
            monthly: newMonthly,
            percent: newPercent,
        });
    };

    const handleMonthlyChange = (index: number, rawValue: string | number) => {
        const rawStr = String(rawValue); // Ensure string
        const monthlyValue = parseFloat(rawStr.replace(/[^0-9.]/g, ''));
        if (isNaN(monthlyValue)) {
            updateItem(index, { annual: null, monthly: null, percent: null });
            return;
        }

        const newMonthly = monthlyValue;
        const newAnnual = newMonthly * 12;
        const newPercent = totalIncome ? (newAnnual / totalIncome) * 100 : 0;

        updateItem(index, {
            annual: newAnnual,
            monthly: newMonthly,
            percent: newPercent,
        });
    };

    const handleEdit = (index: number) => {
        setEditingIndex(index);
    };

    const handleSave = (index: number) => {
        const item = editableData[index];
        if (item) {
            const annualAmount = Number(item.annual) || 0;
            const updatedItem = {
                ...item,
                percent: totalIncome ? (annualAmount / totalIncome) * 100 : 0,
                typeLabel: RISK_MANAGEMENT_TYPES[item.type],
            };
            onSave(updatedItem, updatedItem, index);
        }
        setEditingIndex(null);
    };

    const handleDelete = (item: MappedCashflow, index: number) => {
        if (onDelete) {
            onDelete(item, index);
        }
    };

    const handleTypeChange = (index: number, value: keyof typeof RISK_MANAGEMENT_TYPES) => {
        setEditableData(prevData =>
            prevData.map((item, idx) => (idx === index ? { ...item, type: value } : item))
        );
    };

    const typeOptions = Object.keys(RISK_MANAGEMENT_TYPES).map((key) => ({
        value: key,
        inputDisplay: RISK_MANAGEMENT_TYPES[key],
    }));

    // Calculate totals for columns
    const totalAnnual = useMemo(
        () => editableData.reduce((acc, item) => acc + (Number(item.annual) || 0), 0),
        [editableData]
    );
    const totalMonthly = useMemo(
        () => editableData.reduce((acc, item) => acc + (Number(item.monthly) || 0), 0),
        [editableData]
    );
    const totalPercent = useMemo(
        () => editableData.reduce((acc, item) => acc + (Number(item.percent) || 0), 0),
        [editableData]
    );

    const dataColumns: Array<EuiBasicTableColumn<MappedCashflow>> = columns
        .filter(col => windowSize.width > 767 || (col.field !== 'annual' && col.field !== 'percent')) // Conditionally exclude the 'annual' and 'percent' columns
        .map((col) => {
            let footerContent: string | JSX.Element | undefined;

            if (col.field === 'type') {
                footerContent = <strong>Total:</strong>;
            } else if (col.field === 'annual') {
                footerContent = <strong>{col.formatter ? col.formatter(totalAnnual) : totalAnnual}</strong>;
            } else if (col.field === 'monthly') {
                footerContent = <strong>{col.formatter ? col.formatter(totalMonthly) : totalMonthly}</strong>;
            } else if (col.field === 'percent') {
                footerContent = <strong>{totalPercent.toFixed(2)}%</strong>;
            }

            return {
                field: col.field,
                name: col.label,
                render: (value: any, record: MappedCashflow) => {
                    const index = editableData.indexOf(record);
                    const isEditing = editingIndex === index;

                    if (isEditing) {
                        if (col.field === 'type') {
                            return (
                                <EuiSuperSelect
                                    style={{ width: '100%' }}
                                    options={typeOptions}
                                    valueOfSelected={String(editableData[index]?.type || '')}
                                    onChange={(value) => handleTypeChange(index, value)}
                                    itemLayoutAlign="top"
                                    hasDividers
                                    fullWidth
                                />
                            );
                        }

                        if (col.field === 'percent') {
                            const percentValue = editableData[index][col.field];
                            const displayValue =
                                percentValue !== null && percentValue !== undefined
                                    ? `${percentValue}%`
                                    : '';
                            return (
                                <EuiFieldText
                                    value={displayValue}
                                    onChange={(e) => handlePercentChange(index, e.target.value)}
                                    aria-label={`Edit ${col.label}`}
                                />
                            );
                        }

                        if (col.field === 'annual') {
                            return (
                                <DollarTextField
                                    value={String(editableData[index][col.field] || '')}
                                    onChange={(e: any) => handleAnnualChange(index, e.target.value)}
                                    aria-label={`Edit ${col.label}`}
                                    eui
                                />
                            );
                        }

                        if (col.field === 'monthly') {
                            return (
                                <DollarTextField
                                    value={String(editableData[index][col.field] || '')}
                                    onChange={(e: any) => handleMonthlyChange(index, e.target.value)}
                                    aria-label={`Edit ${col.label}`}
                                    eui
                                />
                            );
                        }

                        // Other fields
                        return (
                            <DollarTextField
                                value={String(editableData[index][col.field] || '')}
                                onChange={(e: any) =>
                                    setEditableData(prevData =>
                                        prevData.map((item, idx2) =>
                                            idx2 === index ? { ...item, [col.field]: e.target.value } : item
                                        )
                                    )
                                }
                                aria-label={`Edit ${col.label}`}
                                eui
                            />
                        );
                    }

                    // View mode
                    if (col.field === 'type') {
                        return RISK_MANAGEMENT_TYPES[record.type] || '';
                    }

                    return col.formatter ? col.formatter(Number(value) || 0) : value;
                },
                footer: footerContent,
            };
        });

    const actionsColumn: EuiBasicTableColumn<MappedCashflow> = {
        name: 'Actions',
        render: (record: MappedCashflow) => {
            const index = editableData.indexOf(record);
            const isEditing = editingIndex === index;
            return (
                <>
                    <EuiButtonIcon
                        iconType={isEditing ? 'check' : 'pencil'}
                        aria-label={isEditing ? 'Save' : 'Edit'}
                        onClick={() => (isEditing ? handleSave(index) : handleEdit(index))}
                    />
                    <EuiButtonIcon
                        iconType="trash"
                        color="danger"
                        aria-label="Delete"
                        onClick={() => handleDelete(record, index)}
                    />
                </>
            );
        },
    };

    const tableColumns = [...dataColumns, actionsColumn];

    return (
        <>
            <Global styles={rmStyles} />
            <EuiFlexGroup>
                <EuiFlexItem>
                    <h2>{title}</h2>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={onAdd} iconType="plusInCircle" color="text" style={{ border: '1px solid' }}>
                        {addLabel}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            <StyledSpacer size="24px" />
            <EuiBasicTable
                responsive={false}
                className="table-border"
                items={editableData}
                columns={tableColumns}
                rowHeader="type"
            />
        </>
    );
};

export default RiskManagementTable;