import React from "react";
import { map } from "lodash";

import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  makeStyles,
  Tooltip,
} from "@material-ui/core";

import {
  EuiCard,
  EuiCallOut,
  EuiFlexGroup
} from "@elastic/eui";

import { StyledSpacer } from "src/components/Global/StyledComponents";

import { GRADUATED_LIFE_EVENTS } from "src/interfaces/lifeEvents.interface";
import FbIcon from "src/components/Icon";
import colors from "src/theme/colors";
import shadows from "src/theme/shadows";
interface LifeEventCategoriesProps {
  onSelect: (type: string) => void;
}

const LifeEventCategories = ({ onSelect }: LifeEventCategoriesProps) => {
  const styles = useStyles();

  const getButton = (item: any, type: string, disabledButton?: boolean) => {
    if (disabledButton) {
      return (
        // 
        <></>
      );
    } else {
      return (
        <EuiCard
          style={{ maxWidth: '174px' }}
          icon={
            <svg width="32" height="32">
              <use xlinkHref={`/assets/images/svg/sprite.svg#${item.icon}`} />
            </svg>
          }
          title={
            <div className={styles.title}>
              {item.buttonLabel}
            </div>
          }
          onClick={() => onSelect(type)}
        />
      );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <EuiFlexGroup direction="row" wrap justifyContent="center" style={{ maxWidth: '600px' }}>
          {map(GRADUATED_LIFE_EVENTS, (item, type) =>
            getButton(item, type, item.disabled)
          )}
        </EuiFlexGroup>
      </div>
      <StyledSpacer size="16px" />
      <EuiCallOut
        title="Add retirement savings, debt repayment goals, and emergency savings later."
        iconType="iInCircle"
        color="primary"
        style={{ maxWidth: '500px', margin: '0 auto' }}
      >
        <p>
          Retirement savings and debt repayment goals are included in Money for Future Goals. Emergency savings are included in Emergency Fund.
        </p>
      </EuiCallOut>
    </>
  );
};

export default LifeEventCategories;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
  },
  disabledCard: {
    boxShadow: shadows[2],
    height: 110,
    border: `1px solid ${colors.blueGray7}`,
    backgroundColor: "#f6fbfd",
    "&>span": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: colors.brandingBlue1,
      cursor: "pointer",
    },
  },
});
